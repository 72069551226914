.root {
  --hero-highlight-color: var(--color-accent-default);
  --offset-top: calc(
    var(--space-xl) + var(--nav-height) + var(--banner-height)
  );

  padding-bottom: var(--space-3xl);
  overflow-x: hidden;
}

.wrapper {
  position: relative;

  padding: var(--offset-top) var(--layout-gutter) var(--space-2xl-3xl)
    var(--layout-gutter);
  background-color: var(--hero-background);
  color: var(--hero-color);
}

.gradient {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.5;
    background: linear-gradient(
      270deg,
      transparent 0%,
      var(--hero-background) 100%
    );
  }

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.root.flipGradient .gradient {
  transform: rotateY(180deg);
}

.illustration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.illustration svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: translate(-50%, -50%);
}

.inner {
  position: relative;
  z-index: 2;
}

.headline {
  max-width: 20ch;
  font-family: var(--font-family-headline);
  font-size: fluid(38px, 72px);
  letter-spacing: -0.015em;
  line-height: 0.95;
}

.subline {
  max-width: 35ch;
  margin-top: var(--space-m);
  opacity: 0.75;
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-s);
  letter-spacing: -0.005em;
  line-height: 1.35;
}

.media {
  --color-text-secondary: var(--base-color-grey-600);
  --color-border: rgba(0 0 0 / 10%);

  width: 100%;
  margin-top: var(--space-3xl);
  padding: var(--space-l);
  padding-bottom: var(--space-xl);
  border: var(--hero-card-border-width) solid var(--hero-card-border-color);
  border-radius: var(--border-radius-default);
  background: var(--hero-card-background);

  color: var(--color-text-default);
}

.featureShowcaseList {
  margin: var(--space-s) 0 0 0;
  padding: 0;
  list-style: none;
}

.featureShowcaseItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: var(--space-xs-s);
  padding-bottom: var(--space-xs-s);
}

.featureShowcaseItemIcon {
  width: 58px;
  height: 58px;
  margin-right: var(--space-m-l);
  color: var(--hero-highlight-color);
}

.featureShowcaseItemBefore {
  opacity: 0.5;
  font-family: var(--font-family-headline);
  font-size: var(--text-body-s);
}

.featureShowcaseItemAfter {
  font-family: var(--font-family-headline);
  font-size: var(--text-body-xl);
}

.clientLogos {
  margin-top: var(--space-xl);
  padding-top: var(--space-l);
  border-top: 1px solid var(--hero-border-color);

  & h3 {
    margin-bottom: var(--space-xl);
  }
}

.clientLogos.bleed {
  margin-right: calc(-1 * var(--layout-gutter));
  margin-left: calc(-1 * var(--layout-gutter));

  & h3 {
    margin-left: var(--layout-gutter);
  }
}

.clientLogoGrid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xl);
  align-items: center;
  justify-content: center;
  margin-top: var(--space-m-l);
}

.clientLogoGridItem {
  display: flex;
  flex: 1 0 150px;
  align-items: center;
  justify-content: center;
}

.clientLogoGridItem .clientLogo {
  display: block;
  max-width: 100%;
  height: auto;
  transform: scale(0.85);
}

/* THEME VARIANTS */
.root[data-theme='light'] {
  --hero-background: var(--color-background-light);
  --hero-color: var(--color-text-default);
  --hero-card-background: var(--color-background-default);
  --hero-card-border-width: 1px;
  --hero-card-border-color: var(--color-border);
  --hero-border-color: var(--color-border);
}

.root[data-theme='light'] .clientLogo {
  opacity: 0.55;
  filter: brightness(0) grayscale(100%);
}

.root[data-theme='dark'] {
  --hero-background: var(--color-background-inverted);
  --hero-color: var(--color-text-on-color);
  --hero-card-background: var(--color-background-default);
  --hero-card-border-width: 0px;
  --hero-card-border-color: transparent;
  --hero-border-color: rgba(255 255 255 / 7.5%);
}

.root[data-theme='dark'] .clientLogo {
  opacity: 0.65;
  filter: brightness(1) grayscale(100%);
}

/* RESPONSIVE */
@media (--small) {
  .clientLogo {
    transform: scale(0.95);
  }

  .clientLogoGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-items: center;
  }
}

@media (--medium) {
  .root {
    padding-bottom: 0;
  }

  .wrapper {
    padding-bottom: var(--space-2xl);
  }

  .upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45vh;
  }

  .textWrapper {
    width: 50%;
  }

  .media {
    width: calc(50% - var(--layout-gutter));
    margin-top: 0;
    margin-right: calc(
      -1 * var(--layout-gutter) + -1 * var(--hero-card-border-width)
    );
    margin-bottom: 0;
    padding: var(--space-xl) var(--space-xl) var(--space-xl-2xl);
    border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
  }

  .clientLogos {
    padding-top: var(--space-s);
  }

  .clientLogoGrid {
    margin-top: var(--space-xl);
  }
}

@media (--max) {
  .media {
    width: 45%;
    margin-right: 0;
    border-radius: var(--border-radius-default);
  }
}
