.root {
  position: relative;
  max-width: max(50vw, 450px);
  margin-right: auto;
  margin-left: auto;
  padding: var(--space-xl) var(--space-xl);
  overflow: hidden;
  border-radius: var(--border-radius-small);
  outline: 1px solid var(--color-border);
  background: var(--color-background-default);
  color: var(--color-text-default);
  aspect-ratio: unset;
}

.tickList {
  margin-bottom: var(--space-l-xl);
}

.instructions {
  max-width: 55ch;
  margin-top: var(--space-m-l);
  margin-bottom: var(--space-m-l);
  color: var(--color-text-secondary);
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
}

/** 
  These are duplicates from inputs.module.css because Hubspot doesn't allow
  us to add classes to individual input fields.
**/

.form label,
.form input[type='submit'] {
  margin-top: var(--space-s-m);
}

.form label {
  display: block;
  margin-bottom: 0.5em;
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.form label[data-variant='obtrusive'] {
  color: var(--color-text-default);
}

.form input[type='text'],
.form input[type='email'],
.form input[type='number'] {
  display: block;
  width: 100%;
  padding: var(--space-xs) var(--space-xs);
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius-small);
  outline: none;
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
  text-overflow: ellipsis;

  &.inputError {
    border-color: var(--color-input-border-alert);
  }
}

.form input[type='submit'] {
  --notch-size: 5px;

  display: inline-flex;
  padding: 1em 1.25em 0.85em;
  border: 0;
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  gap: var(--space-2xs);
  clip-path: polygon(
    0% var(--notch-size),
    var(--notch-size) 0%,
    calc(100% - var(--notch-size)) 0%,
    100% var(--notch-size),
    100% calc(100% - var(--notch-size)),
    calc(100% - var(--notch-size)) 100%,
    var(--notch-size) 100%,
    0% calc(100% - var(--notch-size))
  );

  background: var(--button-color, var(--color-accent-default));
  color: var(--button-text, var(--color-text-on-color));

  &:hover {
    background: var(--button-hover-color, var(--color-accent-hover));
    color: var(--button-hover-text, var(--color-text-on-color));
  }
}

.form input[type='checkbox'] {
  accent-color: var(--color-accent-default);
}

.form textarea {
  resize: none;
}

.form textarea:focus,
.form input:focus {
  border-color: var(--color-accent-default);
}

.form .inputErrorMessages label {
  margin-top: var(--space-2xs);
  font-size: var(--text-body-s);
  color: var(--color-text-alert);
  letter-spacing: 0em;
  text-transform: none;
  font-family: var(--font-family-body);
}

.form ul {
  padding: 0;
  list-style: none;
}

.form ul label {
  text-transform: none;
  font-family: inherit;
  font-size: var(--text-body-s);
}

.form ul[role='checkbox'] input[type='checkbox'] {
  margin-right: var(--space-xs);
  vertical-align: text-top;
}
