.root {
  --offset-top: calc(var(--space-s) + var(--nav-height) + var(--banner-height));
  --hero-illustration-overlap: min(20vh, 150px);
}

.root.themeDark {
  --hero-background: var(--color-background-inverted);
  --hero-illustration-background: var(--color-background-light);
  --hero-color: var(--color-text-on-color);
  --hero-key-feature-background: var(--color-background-light);
  --hero-key-feature-color: var(--color-text-default);
  --hero-key-feature-icon: var(--color-accent-default);
}

.root.themeLight {
  --hero-background: var(--color-background-light);
  --hero-illustration-background: var(--color-background-inverted);
  --hero-color: var(--color-text-default);
  --hero-key-feature-background: var(--color-background-light);
  --hero-key-feature-color: var(--color-text-default);
  --hero-key-feature-icon: var(--color-accent-default);
}

.hero {
  position: relative;
  overflow: hidden;
}

.innerWrapper {
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
  height: 100%;
  padding: var(--offset-top) var(--layout-gutter)
    calc(var(--hero-illustration-overlap) + var(--space-xl));
  background-color: var(--hero-background);
  color: var(--hero-color);
}

.illustration {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.illustration > * {
  position: absolute;
  inset: 0;
  width: auto;
  min-width: 100%;
  height: 100%;
}

.textWrapper {
  position: relative;
}

.claim {
  font-family: var(--font-family-headline);
  font-size: fluid(38px, 72px);
  letter-spacing: -0.01em;
  line-height: 1.05;
}

.subline {
  margin-top: var(--space-m);
  opacity: 0.75;
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-s);
  letter-spacing: -0.005em;
  line-height: 1.35;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  margin-top: var(--space-l-xl);
}

.action {
  width: 100%;
}

.keyFeaturesWrapper {
  position: relative;

  /* Setting the z-index to 2 makes sure the cards are rendered above the
   * illustration when pulling them up using negative margin.
   * This fixes a rendering issue when animation the cards in. */

  z-index: 2;
  margin-top: calc(-0.75 * var(--hero-illustration-overlap));
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.keyFeatures {
  display: grid;
  grid-gap: var(--space-xs);
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}

.keyFeature {
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--space-m-l);
  border-radius: var(--border-radius-default);
  background: var(--hero-key-feature-background);
  color: var(--hero-key-feature-color);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
  line-height: 1.2;
  gap: var(--space-2xs);
  text-decoration: none;
}

.keyFeature:hover {
  background-color: var(--color-accent-default);
  color: var(--color-text-on-color);
}

.keyFeatureIconWrapper {
  flex-grow: 0;
  flex-shrink: 0;
  width: 40px;
  margin-right: var(--space-s-m);
}

.keyFeatureIcon {
  color: var(--hero-key-feature-icon);
  font-size: 40px;
}

.keyFeature:hover .keyFeatureIcon {
  color: var(--color-text-on-color);
}

.keyFeatureBody {
  position: relative;
  height: 100%;
  padding-bottom: 2rem;
}

.keyFeatureHeadline {
  font-size: var(--text-body-l);
}

.keyFeatureText {
  margin-top: var(--space-xs-s);
  opacity: 0.75;
  line-height: 1.4;
}

.keyFeatureLink {
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  gap: var(--space-2xs);
}

.keyFeature:hover .keyFeatureLinkText {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.clientLogos {
  margin-top: var(--space-2xl);
  margin-right: calc(-1 * var(--layout-gutter));
  margin-left: calc(-1 * var(--layout-gutter));
}

/* RESPONSIVE */
@media (--xsmall) {
  .actions {
    flex-direction: row;
  }

  .action {
    width: fit-content;
  }
}

@media (--small) {
  .keyFeatures {
    left: 0;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: var(--space-s) var(--column-gutter);
  }
}

@media (--medium) {
  .claim,
  .subline {
    width: 70%;
  }

  .clientLogos {
    margin-top: var(--space-xl-2xl);
  }

  .actions {
    margin-bottom: var(--space-2xs);
  }
}

@media (--large) {
  .root {
    --hero-illustration-overlap: 100px;
    --offset-top: calc(
      var(--space-xl) + var(--nav-height) + var(--banner-height)
    );
  }

  .claim {
    min-width: 24ch;
  }

  .subline {
    max-width: 50ch;
  }

  .clientLogos {
    margin-top: var(--space-2xl);
  }

  .keyFeatures {
    grid-gap: var(--column-gutter);
    grid-template-columns: repeat(4, 1fr);
  }

  .keyFeature {
    flex-direction: column;
    align-items: flex-start;
  }

  .keyFeatureIconWrapper {
    width: 72px;
    margin-bottom: var(--space-s);
  }

  .keyFeatureIcon {
    margin-left: -0.125em;
    font-size: 64px;
  }
}

@media (orientation: portrait) and (min-width: 600px) {
  .root {
    --hero-illustration-overlap: 40vh;
  }
}
